import APIService from "./api.service";

export type MandateEffectIndustry = {
    id?: number;
    effect: number;
    industry: number;
    causesRevReduction: number;
    effectDescription: string;
    isApproved: number;
    isPublic: number;
    effectTitle: string;
    createdBy: string;
    industryGroup?: number;
};

class MandateEffectIndustryService extends APIService<MandateEffectIndustry> {
    constructor() {
        super("/mandate-effect-industries");
    }
}

export default new MandateEffectIndustryService();
