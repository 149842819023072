import { ChangeEvent, useEffect, useState } from "react";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import RecordingButton from "../../layout/recording-button";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getProcessflowProgresses } from "../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import Loader3 from "../../sections/utilities/Loader3";
import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import PrintPre from "../../layout/print-pre";
import CheckBoxNeoGenControlled from "../../layout/checkbox-controlled";

export default function Package({ companyId, user }: { companyId: number; user: User | undefined }) {
    const authToken = getAuthTokenNoThrow() || "";
    const [usersProgressId, setUsersProgressId] = useState(-1);
    const [enabledDocs, setEnabledDocs] = useState<number[]>([
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    ]);
    // useEffect(() => {
    async function getProgress() {
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId: user?.id, group: 7 },
        });
        console.log(usersProgress);
        setUsersProgressId(usersProgress?.id || -1);
    }
    getProgress();

    const helpDocsQuery = useQuery(["HelpDocs"], async () => {
        const response = await helpDocsService.getAll();
        console.log(response);
        // alert("!")
        if (response) {
            return response.data;
        }
    });
    return (
        <>
            {usersProgressId === -1 || helpDocsQuery.isLoading ? (
                <Loader3 />
            ) : (
                <>
                    {/* <PrintPre>{helpDocsQuery.data}</PrintPre> */}

                    <div className="m-5  gap-5 border p-5 rounded-xl dark:border-gray-600">
                        {helpDocsQuery.data?.map((helpDoc) => {
                            return (
                                <div key={helpDoc.id} className="flex">
                                    <CheckBoxNeoGenControlled
                                        label={helpDoc.title}
                                        setValue={() => {
                                            if (enabledDocs.includes(helpDoc.id ?? -1)) {
                                                setEnabledDocs(enabledDocs.filter((id) => id !== helpDoc.id));
                                            } else {
                                                setEnabledDocs([...enabledDocs, helpDoc.id ?? -1]);
                                            }
                                        }}
                                        value={enabledDocs.includes(helpDoc.id ?? -1)}
                                        name={""}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="m-5 grid grid-cols-3 gap-5">
                        <RecordingButton
                            title={"Generate Word Document"}
                            icon={"fas fa-file-word"}
                            download={
                                "https://generate-package.clearerc.com/word?processflowID=" +
                                usersProgressId +
                                "&helpDocs=" +
                                enabledDocs.join(",")
                            }
                        />
                        <RecordingButton title={"Generate PDF"} icon={"fas fa-file-pdf"} />
                        <RecordingButton title={"Generate Excel File"} icon={"fas fa-file-excel"} />
                    </div>
                </>
            )}
        </>
    );
}
