import React from "react";
import PageDescription from "../../../layout/page-description";
import { Table } from "../../../layout/table";
import TableNeogen from "../../../layout/table-neogen";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import industryService from "../../../services/industry.service";
import industryGroupsService, { IndustryGroup } from "../../../services/industry-groups.service";
import industryGroupMembersService from "../../../services/industry-group-members.service";
import Loader3 from "../../utilities/Loader3";
import AddMandateType from "./modals/add-mandate-type";
import SwalNeogenFire from "../../../layout/swal-neogen";
import mandateTypeService, { MandateType } from "../../../services/mandate-type.service";
import { Mandate } from "../../../services/mandate.service";
import { useParams } from "react-router-dom";
import mandateEffectService from "../../../services/mandate-effect.service";
import mandateEffectIndustryService, { MandateEffectIndustry } from "../../../services/mandate-effect-industry.service";
import PrintPre from "../../../layout/print-pre";
import { MenuList } from "../../../layout/select-virtualized";
import Select from "react-select";
import AddImpact from "./modals/add-impact";
import { set } from "lodash";
import mandateEffectIndustryGroupService, {
    MandateEffectIndustryGroup,
} from "../../../services/mandate-effect-industry-group.service";
export default function Impacts({ type }: { type: "industry" | "industryGroup" }) {
    const { id } = useParams();
    const [showAddImpact, setShowAddImpact] = React.useState(false);
    const [mandate, setMandate] = React.useState<Mandate | null>(null);
    const [filters, setFilters] = React.useState<any[]>([]);
    const [impact, setImpact] = React.useState<MandateEffectIndustry | MandateEffectIndustryGroup | null>(null);

    const options = [];
    for (let i = 0; i < 10000; i = i + 1) {
        options.push({ value: i, label: `Option ${i}` });
    }

    const queryCache = useQueryClient();
    const mandateEffectsQuery = useQuery(["mandateEffects", id], async () => {
        const result = await mandateEffectService.getOne(id ?? "");
        return result?.data;
    });

    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });

    const industryGroupsQuery = useQuery(["industryGroups"], async () => {
        const result = await industryGroupsService.getAll();
        return result?.data ?? [];
    });

    const impactsQuery = useQuery(
        [type === "industry" ? "mandateEffectIndustry" : "mandateEffectIndustryGroup"],
        async () => {
            const result = await (type === "industry"
                ? mandateEffectIndustryService.getAll()
                : mandateEffectIndustryGroupService.getAll());
            return result?.data ?? [];
        },
    );

    if (mandateEffectsQuery.isLoading || industriesQuery.isLoading || impactsQuery.isLoading) {
        return <Loader3 />;
    }

    return (
        <>
            <PageDescription
                title={
                    (type === "industry" ? "Industry Impacts" : "Industry Group Impacts") +
                    " from " +
                    (mandateEffectsQuery.data?.effectName ?? "")
                }
                description={"Manage Mandate Types " + id}
                buttons={[
                    {
                        label: "Go Back",
                        onClick: () => {
                            window.location.href = "/admin/mandate-effects";
                        },
                        type: "secondary",
                        icon: "fas fa-angle-left",
                    },
                    {
                        label: "Add Impact",
                        onClick: () => {
                            setImpact(null);
                            setShowAddImpact(true);
                        },
                        icon: "fas fa-plus",
                    },
                ]}
                // filters={[
                //     {
                //         label: "Filter by Industry Group",
                //         value: "",
                //         options:
                //             industriesQuery.data?.map((i) => {
                //                 return { id: (i.id ?? "").toString(), name: i.name ?? "" };
                //             }) ?? [],
                //         onChange: (idx) => {
                //             console.log({ idx });
                //         },
                //     },
                // ]}
            ></PageDescription>
            <div className="w-full bg-white p-5 rounded-lg dark:bg-slate-700">
                Filter
                <Select
                    components={{ MenuList }}
                    isMulti
                    defaultValue={filters}
                    className=" dark:bg-slate-900  dark:border-slate-900"
                    classNames={{
                        clearIndicator: () => "",
                        control: () => "dark:bg-slate-700 dark:border-slate-900",
                        // control: () => "bg-red-300",
                        // dropdownIndicator: () => "bg-red-300",
                        // group: () => "bg-red-300",
                        // groupHeading: () => "bg-red-300",
                        indicatorsContainer: () => "dark:bg-slate-800",
                        indicatorSeparator: () => "dark:bg-slate-600",
                        input: () => "dark:bg-slate-700 border-slate-700",
                        // loadingIndicator: () => "bg-red-300",
                        // loadingMessage: () => "bg-red-300",
                        // menu: () => "bg-red-300",
                        // menuList: () => "bg-red-300",
                        // menuPortal: () => "bg-red-300",
                        // multiValue: () => "bg-red-300",
                        multiValueLabel: () => "dark:bg-slate-500",
                        multiValueRemove: () => "dark:bg-slate-900",
                        noOptionsMessage: () => "dark:bg-slate-300",
                        // option: () => "dark:bg-slate-300",
                        // placeholder: () => "text-red-300",
                        // singleValue: () => "bg-red-300",
                        valueContainer: () => "dark:bg-slate-800",
                    }}
                    onChange={(e) => {
                        setFilters(Array.isArray(e) ? e : []);
                    }}
                    options={
                        type === "industry"
                            ? industriesQuery.data
                                  ?.map((i) => {
                                      return { value: i.id, label: i.name };
                                  })
                                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                            : industryGroupsQuery.data
                                  ?.map((i) => {
                                      return { value: i.id, label: i.name };
                                  })
                                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                    }
                />
            </div>
            {impactsQuery.isLoading ? (
                <Loader3 />
            ) : (
                <div className="mt-5">
                    {/* <PrintPre>{filters}</PrintPre>
                    {filters.map((f) => {
                        return (
                            <div key={f.value}>
                                {f.value} {f.label}
                                <PrintPre>{industriesQuery.data?.[f.value - 1]}</PrintPre>
                            </div>
                        );
                    })} */}
                    <TableNeogen
                        entries={
                            impactsQuery.data?.map((i) => {
                                return {
                                    id: i.id,
                                    title: i.effectTitle,
                                    description: i.effectDescription,
                                    industry:
                                        type === "industry"
                                            ? industriesQuery.data?.find((x) => x.id === i.industry)?.name
                                            : industryGroupsQuery.data?.find((x) => x.id === i.industryGroup)?.name,
                                };
                            }) ?? []
                        }
                        actions={[
                            {
                                label: "Edit",
                                onClick: (entry) => {
                                    // console.log({ entry: impactsQuery.data?.find((i) => i.id === entry) ?? null });
                                    // setMandateType(mandateEffectsQuery.data?.find((i) => i.id === entry) ?? null);
                                    setImpact(
                                        (impactsQuery.data as any)?.find(
                                            (i: MandateEffectIndustry | MandateEffectIndustryGroup) => i.id === entry,
                                        ) ?? null,
                                    );
                                    setShowAddImpact(true);
                                },

                                icon: "fas fa-edit",
                            },
                            {
                                label: "Delete",
                                type: "danger",
                                onClick: (entry) => {
                                    SwalNeogenFire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No, keep it",
                                        confirmButtonColor: "#d33",
                                        reverseButtons: true,
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            // Get a list of all the industryGroupMembers that are in this industryGroup
                                            // and delete them
                                            // const entriesToDelete =
                                            //     industryGroupMembersQuery.data?.filter((i) => i.industryGroup === entry) ??
                                            //     [];
                                            // for (const entry of entriesToDelete) {
                                            //     await industryGroupMembersService.deleteByID(entry.id ?? -1);
                                            // }
                                            // await industryService.deleteByID(entry);
                                            // queryCache.invalidateQueries(["industryGroups"]);
                                            // queryCache.invalidateQueries(["industryGroupMembers"]);
                                            // industriesQuery.refetch();
                                            // SwalNeogenFire({
                                            //     title: "Deleted!",
                                            //     text: "Your file has been deleted.",
                                            //     icon: "success",
                                            //     showConfirmButton: false,
                                            //     timer: 1500,
                                            // });
                                        }
                                    });
                                },
                                icon: "fas fa-trash",
                            },
                        ]}
                    />
                </div>
            )}
            {showAddImpact && (
                <AddImpact
                    effect={mandateEffectsQuery.data ?? {}}
                    show={showAddImpact}
                    close={() => setShowAddImpact(false)}
                    impact={impact}
                    type={type}
                    // mandateType={mandateType}
                />
            )}
        </>
    );
}
