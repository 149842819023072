import React from "react";
import PageDescription from "../../../layout/page-description";
import { Table } from "../../../layout/table";
import TableNeogen from "../../../layout/table-neogen";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import industryService from "../../../services/industry.service";
import industryGroupsService, { IndustryGroup } from "../../../services/industry-groups.service";
import industryGroupMembersService from "../../../services/industry-group-members.service";
import Loader3 from "../../utilities/Loader3";
import AddMandateEffect from "./modals/add-mandate-effect";
import SwalNeogenFire from "../../../layout/swal-neogen";
import mandateEffectService, { MandateEffect } from "../../../services/mandate-effect.service";
import { Mandate } from "../../../services/mandate.service";

export default function MandateEffects() {
    const [showAddMandateEffect, setShowAddMandateEffect] = React.useState(false);
    const [mandate, setMandate] = React.useState<Mandate | null>(null);
    const [mandateEffect, setMandateEffect] = React.useState<MandateEffect | null>(null);

    const queryCache = useQueryClient();
    const mandateEffectsQuery = useQuery(["mandateEffects"], async () => {
        const result = await mandateEffectService.getAll();
        return result?.data ?? [];
    });

    return (
        <>
            <PageDescription
                title="Mandate Effects"
                description={
                    "Mandate Effects are the things that a mandate can require a company to do." +
                    "For example, a mandate might require people to wear masks in public. The mandate effect would be 'Wear a mask in public.' " +
                    "There are also mandate industry effects and mandate industry group effects. These are the same as mandate effects, " +
                    "but they are the impacts that these mandates have on industries and industry groups."
                }
                buttons={[
                    {
                        label: "Add Mandate Effect",
                        onClick: () => {
                            setMandateEffect(null);
                            setShowAddMandateEffect(true);
                        },
                        icon: "fas fa-plus",
                    },
                    {
                        label: "Manage Industries",
                        onClick: () => {
                            window.location.href = "/admin/industries";
                        },
                        icon: "fas fa-building-circle-arrow-right",
                        type: "info",
                    },
                    {
                        label: "Manage Mandates",
                        onClick: () => {
                            window.location.href = "/admin/mandates";
                        },
                        icon: "fas fa-file-lines",
                        type: "secondary",
                    },
                ]}
            />
            {mandateEffectsQuery.isLoading ? (
                <Loader3 />
            ) : (
                <TableNeogen
                    entries={
                        mandateEffectsQuery.data?.map((i) => {
                            return {
                                id: i.id,
                                name: i.effectName,
                                // description: i.notes,
                                // industries:
                                //     industryGroupMembersQuery.data
                                //         ?.filter((m) => m.industryGroup === i.id)
                                //         .map((m) => {
                                //             return (
                                //                 industriesQuery.data?.find((in1) => in1.id === m.industry)?.name ?? ""
                                //             );
                                //         })
                                //         ?.join(", ") ?? "",
                            };
                        }) ?? []
                    }
                    actions={[
                        {
                            label: "Industry Impacts (0)",

                            onClick: (entry) => {
                                window.location.href = "/admin/impacts/industry/" + entry;
                            },
                            type: "info",
                            icon: "fal fa-building-columns",
                        },
                        {
                            label: "Industry Group Impacts (0)",
                            onClick: (entry) => {
                                window.location.href = "/admin/impacts/industry-groups/" + entry;
                            },
                            type: "info",
                            icon: "fas fa-building-columns",
                        },
                        {
                            label: "Edit",
                            onClick: (entry) => {
                                // console.log({ entry });
                                setMandateEffect(mandateEffectsQuery.data?.find((i) => i.id === entry) ?? null);
                                setShowAddMandateEffect(true);
                            },

                            icon: "fas fa-edit",
                        },
                        {
                            label: "Delete",
                            type: "danger",
                            onClick: (entry) => {
                                SwalNeogenFire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No, keep it",
                                    confirmButtonColor: "#d33",
                                    reverseButtons: true,
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        // Get a list of all the industryGroupMembers that are in this industryGroup
                                        // and delete them
                                        // const entriesToDelete =
                                        //     industryGroupMembersQuery.data?.filter((i) => i.industryGroup === entry) ??
                                        //     [];
                                        // for (const entry of entriesToDelete) {
                                        //     await industryGroupMembersService.deleteByID(entry.id ?? -1);
                                        // }
                                        // await industryService.deleteByID(entry);
                                        // queryCache.invalidateQueries(["industryGroups"]);
                                        // queryCache.invalidateQueries(["industryGroupMembers"]);
                                        // industriesQuery.refetch();
                                        // SwalNeogenFire({
                                        //     title: "Deleted!",
                                        //     text: "Your file has been deleted.",
                                        //     icon: "success",
                                        //     showConfirmButton: false,
                                        //     timer: 1500,
                                        // });
                                    }
                                });
                            },
                            icon: "fas fa-trash",
                        },
                    ]}
                />
            )}
            {showAddMandateEffect && (
                <AddMandateEffect
                    show={showAddMandateEffect}
                    close={() => setShowAddMandateEffect(false)}
                    mandateEffect={mandateEffect}
                />
            )}
        </>
    );
}
